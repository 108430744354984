<template>
  <KeepAlive>
    <component :is="component" v-bind="props"></component>
  </KeepAlive>
</template>

<script lang="ts">
import { isString } from "lodash";

export default defineComponent({
  name: "NavContent",
  props: {
    menu: { type: Array, required: true },
    nav: { required: true },
  },
  computed: {
    component(): string {
      const nav = this.getNav();
      return nav?.component || "div";
    },
    props(): any {
      const nav = this.getNav();
      return nav?.props || {};
    },
  },
  methods: {
    getNav(): any {
      if (isString(this.nav)) {
        return this.menu.find((m: any, i) => (m.key || i) == this.nav);
      }
      return this.nav;
    },
  },
});
</script>
